import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/aplikacja-crm.png";
import Lightbox from "../components/lightBox";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "aplikacja-crm-online-quickcrm.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "prosty-crm-raporty.png" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "Zarzadzanie-zadaniami-w-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "prosty-crm-Zarzadzanie-uprawnieniami.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const AplikacjaCRM = ({ data }) => {
  return (
    <Artykul
      title="Aplikacja CRM. Prowadzenie firmy nigdy nie było tak proste"
      articleImage={obrazekArtykulu}
      keywords={["aplikacja crm"]}
      articleImageAlt="aplikacja-CRM"
      metaTitle="Aplikacja CRM. Prowadzenie firmy nigdy nie było tak proste."
      metaDescription="Prowadzenie firmy przy pomocy smartfona ✅ Dziś możesz skorzystać z systemu CRM na swoim telefonie.✅ Aplikacja CRM czy system w przeglądarce?"
    >
      <br />
      <h2>Aplikacja CRM a prowadzenie firmy</h2>
      <p>
        Prowadzenie firmy za pomocą smartfona. Czy to jest możliwe? Chcesz mieć
        spokojny umysł i wszystko pod kontrolą? Dziś możesz skorzystać z systemu
        CRM na swoim telefonie. W przypadku niektórych systemów nie jest
        potrzebna nawet aplikacja CRM, wystarczy dostęp do Internetu i
        przeglądarki. Dowiedz się, jak możesz oszczędzić czas i zredukować
        poziom stresu, wykorzystując prosty w obsłudze system CRM.
      </p>
      <h3>Prowadzenie firmy. Czy to musi być męczące?</h3>
      <p>
        Z czym kojarzy się słowo przedsiębiorca? Jest to na pewno ktoś, kto
        prowadzi działalność gospodarczą. Ktoś, kto zatrudnia sam siebie. To co
        wiąże się z taką pracą, to niestety ciągły stres, często praca przez
        całą dobę i niekończące się telefony. Ale czy prowadzenie firmy musi
        wiązać się z taką katorgą dla przedsiębiorcy? Jest on przecież nie tylko
        właścicielem firmy, ale zwykłym człowiekiem, który jak każdy potrzebuje
        spokoju i czasu dla siebie. Zapewnić mu to może wykorzystanie
        nowoczesnej aplikacji CRM.
      </p>
      <h3>Czym jest system CRM?</h3>
      <p>
        CRM to skrót oznaczający zarządzanie relacjami z klientami. Stanowi
        narzędzie ułatwiające prowadzenie firmy, poprzez porządkowanie procesów
        w niej zachodzących, w szczególności związanych z kontaktem z klientami.
        Niezależnie od rozmiarów działalności, każdy znajdzie w nim rozwiązania
        odpowiadające potrzebom jego przedsiębiorstwa. Pozytywne opinie
        użytkowników systemów świadczą o skuteczności CRM-u w środowisku
        biznesowym. Zarówno w dużych firmach, gdzie potrzebne są obszerne
        rozwiązania szyte na miarę, jak i w małych przedsiębiorstwach. Prosty
        system CRM jest dla mikrofirmy idealnym rozwiązaniem. Odpowiedni system
        dla małej firmy wspiera zarówno sprzedaż, jak i pomaga w planowaniu
        pracy.
      </p>

      <h3>Dostęp – aplikacja CRM</h3>
      <p>
        Przy prowadzeniu własnego biznesu właściciel jest nierozerwalnie
        związany z samą firmą. Dlatego ważne jest, aby przedsiębiorca miał stały
        dostęp do narzędzi, umożliwiających mu zarządzanie. Istnieją tutaj dwie
        drogi, którymi może pójść. Jedną z nich jest właśnie aplikacja CRM,
        której może używać na swoim telefonie czy komputerze. Takie rozwiązanie
        wymaga instalacji i można z niego korzystać tylko na tych urządzeniach,
        na których wcześniej wgrane zostało oprogramowanie. Nie możemy go użyć
        na innym telefonie bez wcześniejszej instalacji programu. Nie mając przy
        sobie swojego własnego sprzętu, tracimy dostęp do systemu.
      </p>
      <p>
        <Lightbox images={[data.zdjecie1]} />
        Moblina aplikacja CRM, którą masz zawsze przy sobie. Kliknij, aby
        powiększyć.
      </p>
      <h3>Stały dostęp – system w przeglądarce</h3>
      <p>
        Drugim rozwiązaniem jest oprogramowanie dostępne w SaaS. Trudne pojęcie?
        Już spieszę z tłumaczeniem. Skrót oznacza z angielskiego System as a
        Service – system jako usługa. Polega to na tym, że producent
        oprogramowania udostępnia system nie poprzez osobną aplikację CRM, lecz
        za pomocą przeglądarki. Dostęp uzyskujemy po zalogowaniu się, a obsługa
        interfejsu jest tak prosta, jak obsługa innych stron internetowych. Taki
        wybór ma wiele zalet. Po pierwsze, dostęp do programu masz z każdego
        komputera, tabletu czy telefonu, niezależnie od systemu operacyjnego,
        czy miejsca, w którym jesteś. Wystarczy Ci przeglądarka i dostęp do
        Internetu. Po drugie, taka wersja systemu daje więcej swobody, ponieważ
        ma więcej możliwości dostosowania wyglądu i jest częściej aktualizowana.
        Wadą takich rozwiązań jest fakt, że musisz logować się za każdym razem,
        kiedy chcesz skorzystać z systemu. Z drugiej strony to wyraźnie zwiększa
        bezpieczeństwo Twoich danych, których nie przechowujesz na swoim dysku,
        lecz na serwerze. Umożliwia Ci to też zdalny dostęp do wszystkich
        ważnych plików.
        <br />
        <br />
        <hr />
        <cite>
          Przykład: masz umówione spotkanie z partnerem, z którym masz podpisać
          nowy kontrakt. Przez przypadek zapomniałeś zabrać ze sobą wzoru umowy.
          Aby uniknąć wpadki wystarczy, że dołączysz wcześniej ten plik do karty
          kontrahenta, z którym podpisujesz umowę. W ten sposób będziesz miał
          dostęp do tego załącznika nawet z telefonu, ponieważ wszystkie dane
          gromadzone w systemie są przechowywane w chmurze.
        </cite>
        <br />
        <br />
      </p>
      <h3>Jakie daje możliwości?</h3>
      <p>
        Prosty i łatwy w obsłudze system CRM powinien udostępniać dokładnie to,
        czego potrzebuje Twoja firma. Szanse sprzedaży pozwolą Ci na
        koordynowanie i porządkowanie procesu sprzedaży usług i produktów.
        Dostęp do bazy kontrahentów umożliwia błyskawiczne sprawdzanie
        wszystkich potrzebnych informacji o klientach, a kalendarz i zadania
        dają narzędzie do organizacji czasu. To jednak nie wszystko. Ogromną
        zaletą systemu jest dostęp do raportów, które pozwalają obiektywnie
        oceniać rezultaty wysiłków Twoich pracowników. Co więcej, system daje
        możliwość ustalenia uprawnień i przygotowania spersonalizowanego pulpitu
        dla każdego pracownika. Wbudowany komunikator ułatwia wymianę informacji
        wewnątrz firmy i pozwala na dzielenie się plikami.
      </p>

      <h3>Zdalne zarządzanie firmą</h3>
      <p>
        Dzięki opisanym funkcjom systemu CRM, możesz zarządzać firmą mobilnie.
        Taka możliwość zdaje się niezwykle kusząca, szczególnie dla
        przedsiębiorcy, który jest zmęczony niekończącymi się telefonami i
        koniecznością nagłych wizyt w siedzibie. Pozwól sobie planować spotkania
        z wyprzedzeniem i przydzielać zadania pracownikom za pośrednictwem CRM.
        Przez telefon możesz sprawdzić wszystkie dane dotyczące klientów i
        dowiedzieć się, na jakim etapie jest ich obsługa. Jeśli aplikacja CRM
        jest wyposażona w komunikator, będziesz mógł kontaktować się z
        pracownikami, załatwiając bieżące sprawy bez konieczności dzwonienia do
        nich.
      </p>
      <h3>Codzienna praca może być przyjemniejsza</h3>
      <p>
        Prowadząc firmę, trzeba pamiętać o wielu istotnych terminach i sprawach,
        związanych z obsługą naszych klientów. Aby nie zgubić się w tym, warto
        zdecydować się na CRM on-line, który zorganizuje Twój dzień za Ciebie.
        Wystarczy wprowadzić do niego planowane spotkania i zadania, a zostaną
        one przeniesione do interaktywnego kalendarza. Prowadzenie działalności
        nigdy nie było tak łatwe i przyjemne. Dzięki rozwiązaniom, które kryje
        za sobą CRM w aplikacji internetowej, zarządzanie firmą stanie się
        prostsze. Uwolnisz w ten sposób potencjał, który mógł być dotychczas
        blokowany przez brak rozwiązań organizacyjnych. Odpowiadając na pytanie
        z początku tego artykułu: tak, możliwe jest zarządzanie firmą przez
        smartfona. Potrzebne jest do tego jednak odpowiednie oprogramowanie.
        Jeśli nie wiesz, jakie wybrać dla swojej firmy, koniecznie sprawdź{" "}
        <Link to="/jaki-crm/"> ten artykuł</Link>.
      </p>
      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj system CRM"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default AplikacjaCRM;
